class CookieConsent extends HTMLElement {
    constructor() {
        super();
        this.consentUrl = this.getAttribute('consent-url') || '/';
    }

    connectedCallback() {
        this.render();
        this.initializeCookieConsent();
    }

    render() {
        this.innerHTML = `
            <link rel="stylesheet" href="/assets/css/cookie-monster.css">
        `;
    }

    initializeCookieConsent() {
        const script = document.createElement('script');
        script.src = 'https://cdn.jsdelivr.net/npm/cookieconsent@3/build/cookieconsent.min.js';
        script.onload = () => {
            this.setupCookieConsent();
        };
        document.head.appendChild(script);
    }

    setupCookieConsent() {
        window.cookieconsent.initialise({
            autoOpen: true,
            theme: "classic",
            position: "bottom-right",
            type: "opt-out",
            content: {
                message: 'This website uses cookies to improve your experience.',
                dismiss: 'Got it!',
                deny: 'Decline',
                link: 'Learn more',
                href: '/privacy-policy/',
                close: '&#x274c;',
                policy: '🍪',
                target: '_self'
            },
            elements: {
                allow: '<button aria-label="Allow Cookies" tabindex="0" class="cc-btn cc-allow tt-main-menu-list">Accept</button>',
               },
            revokable: true,
            layout: 'basic',
            onInitialise: (status) => {
                this.handleStatusChange(status);
            },
            onStatusChange: (status) => {
                this.handleStatusChange(status);
            },
            onRevokeChoice: () => {
                this.handleRevokeChoice();
            }
        });
    }

    handleStatusChange(status) {
        if (status === 'allow') {
            this.enableCookies();
        } else {
            this.disableCookies();
        }
    }

    handleRevokeChoice() {
        // this.disableCookies();
    }

    enableCookies() {
        console.log('enabled cookies');
        localStorage.setItem('cookies', 'enabled');
        // TODO Add your code to enable cookies or tracking here
    }

    disableCookies() {
        console.log('disabled cookies');
        localStorage.setItem('cookies', 'disabled');
        // TODO Add your code to disable cookies or tracking here
    }
}

customElements.define('cookie-consent', CookieConsent);